.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 50px;
  background-color: #4369b2;
  padding: 0 8px;
}

.col-left,
.col-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.col-right {
  cursor: pointer;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
}

.arrow-left {
  color: white;
  cursor: pointer;
}

.year {
  color: #ffff;
}
