.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 180px;
  background-color: #5687e4;
  padding: 10px;
}

.page-image {
  max-width: 50%;
  height: 180px;
  opacity: 0.3;
}

.page-title {
  padding: 2rem;
  max-width: 50%;
  align-items: flex-start;
  text-align: right;
}
