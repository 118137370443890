body {
  background-color: #3e63a7;
}

.title {
  color: #fff;
}

.mic {
  font-size: 20px;
  color: #fff;
}

.gear {
  font-size: 20px;
  color: #fff;
}

.containerMain {
  max-width: 100%;
  background-color: #4369b2;
  padding: 0;
  color: #fff;
}

.title-sec {
  max-width: 100%;
  background-color: #4369b2;
  padding: 0 8px;
  height: 30px;
}

.section-title h4 {
  padding: 3px 0;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
}

.navbar .nav-item {
  width: 50%;
  height: 150px;
  box-sizing: border-box;
  padding: 0.6rem;
  color: #fff;
  background-color: #3e63a7;
  transition: transform 1.9s ease;
}

.navbar .nav-item:nth-child(1),
.navbar .nav-item:nth-child(4n),
.navbar .nav-item:nth-child(4n + 1) {
  background-color: #4268af;
}

.navbar .nav-item:hover {
  background-color: #5687e4;
  transform: scale(0.9);
}

.nav-link {
  text-decoration: none;
}

.right-arrow {
  width: 100%;
  display: flex;
  font-size: 20px;
  justify-content: right;
  margin: 0 auto;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: row;
  text-transform: uppercase;
  padding: 0 8px;
}

.map {
  width: 50%;
}

.map img {
  width: 100%;
  height: 150px;
  opacity: 0.3;
  object-fit: fill;
  margin-top: -20px;
}

.content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
  margin: 0;
}

.heading-color {
  color: #fff;
}
